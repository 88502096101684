import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import 'bootstrap/dist/js/bootstrap';
import 'jquery-jpostal-ja';
import twitter from 'twitter-text';
import Vue from 'vue/dist/vue.esm';
import TurbolinksAdapter from 'vue-turbolinks';
import OfferForm from '../components/OfferForm.js';
import PhotoIdentity from '../components/PhotoIdentity';
import IdentityDocumentInput from '../components/IdentityDocumentInput';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import '../lib/icon';
if (typeof Element.prototype.animate !== 'function') {
  import('web-animations-js/web-animations.min');
}
import slideForHitaimen from '../lib/slide_for_hitaimen';

if (process.env.NODE_ENV !== 'development') {
  Vue.config.silent = true;
  Sentry.init({
    dsn: 'https://40af867a477f49c38f6f4e3b0e356e94@o426469.ingest.sentry.io/5368389',
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  });
}

Rails.start();
Turbolinks.start();
Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  if (
    typeof window.fetch === 'function' &&
    typeof Array.prototype.map === 'function'
  ) {
    slideForHitaimen();
  }

  new Vue({
    el: document.getElementById('offer-form'),
    ...OfferForm,
  });

  $('#client_email, #client_email_confirmation').on('keyup change', () => {
    let email = $('#client_email').val();
    let email_confirmation = $('#client_email_confirmation').val();
    let submit = $('[type=submit]');
    let result = !(
      email == email_confirmation &&
      email.length > 0 &&
      email_confirmation.length > 0
    );
    submit.attr('disabled', result);
  });

  const $tweet_text = $('#tweet_text');
  const $tweet_submit = $('#submit-tweet');
  $tweet_text.on('change keyup', function () {
    let text = $tweet_text.val();
    let parsed_text = twitter.parseTweet(text);
    $tweet_submit.attr('disabled', !parsed_text.valid);
    if (!parsed_text.valid) {
      $tweet_text.addClass('is-invalid');
    } else {
      $tweet_text.removeClass('is-invalid');
    }
  });

  $('#zipcode').jpostal({
    postcode: ['#zipcode'],
    address: {
      '#address1': '%3%4%5',
    },
    url: {
      http: 'https://jpostal-1006.appspot.com/json/',
      https: 'https://jpostal-1006.appspot.com/json/',
    },
  });

  new Vue({
    el: document.getElementById('photo-approve-form'),
    name: 'PhotoApproveForm',
    components: {
      PhotoIdentity,
    },
    data() {
      return {
        setting: undefined,
        attachments: {
          1: false,
          2: false,
          3: false,
          4: false,
        },
      };
    },
    computed: {
      isAllAttached() {
        return Object.values(this.attachments).every(
          (attachment) => attachment
        );
      },
    },
    async mounted() {
      const fileInput = this.$refs.file;
      this.setting = {
        directUploadUrl: fileInput.dataset.directUploadUrl,
        name: fileInput.name,
      };
    },
    methods: {
      async cameraCheck() {
        let stream;
        try {
          stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: this.facingMode },
          });
        } catch (e) {
          return false;
        }
        stream.getVideoTracks().forEach((track) => track.stop());
        return true;
      },
      onAttached(index) {
        this.attachments[index] = true;
      },
    },
  });

  new Vue({
    el: document.getElementById('camera-check'),
    name: 'CameraCheck',
    data() {
      return {
        result: false,
      };
    },
    async mounted() {
      if (!location.search.includes('photo')) {
        this.result = await this.cameraCheck();
      }
    },
    methods: {
      async cameraCheck() {
        let stream;
        try {
          stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: this.facingMode },
          });
        } catch (e) {
          return false;
        }
        stream.getVideoTracks().forEach((track) => track.stop());
        return true;
      },
    },
  });

  new Vue({
    el: document.getElementById('identity-document-form'),
    components: {
      IdentityDocumentInput,
    },
    data() {
      return {
        setting1: undefined,
        setting2: undefined,
        attachments: {
          1: false,
          2: false,
        },
      };
    },
    computed: {
      isAllAttached() {
        return Object.values(this.attachments).every(
          (attachment) => attachment
        );
      },
    },
    mounted() {
      this.setting1 = this.getSetting(this.$refs.file1);
      this.setting2 = this.getSetting(this.$refs.file2);
    },
    methods: {
      getSetting(input) {
        const fileInput = input;
        return {
          directUploadUrl: fileInput.dataset.directUploadUrl,
          name: fileInput.name,
        };
      },
      onAttached(index) {
        this.attachments[index] = true;
      },
    },
  });
});
